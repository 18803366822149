import styled from '@emotion/styled';

import { colors, fonts } from './constants';

export const Input = styled('input')({
  padding: '11px 13px',
  height: 40,
  border: `1px solid ${colors.lightGrey}`,
  borderRadius: 3,
  fontSize: 14,
  fontFamily: fonts.text,
  fontWeight: 400,
  color: colors.blue,
  outline: 'none',
  '::placeholder': {
    fontWeight: 300,
    color: colors.grey,
  },
  ':focus': {
    boxShadow: `0 0 0 3px ${colors.teal}`,
  },
});

export function LabelledInput({ label, inputProps, styles = {} }) {
  return (
    <label
      css={{
        fontWeight: 400,
        display: 'flex',
        flexDirection: 'column',
        ...styles,
      }}
    >
      <span css={{ fontSize: 14, marginBottom: 8 }}>{label}</span>
      <Input {...inputProps} />
    </label>
  );
}

export const Button = styled('button')(
  {
    backgroundColor: colors.paleBlue,
    color: '#fff',
    fontSize: 14,
    fontWeight: 300,
    height: 38,
    borderRadius: 2,
    border: 'none',
    outline: 'none',
    padding: '0 16px',
    ':active': {
      backgroundColor: colors.blue,
    },
    ':focus': {
      boxShadow: `0 0 0 3px ${colors.teal}`,
    },
  },
  props => {
    return props.danger
      ? {
          border: `2px solid ${colors.red}`,
          backgroundColor: colors.white,
          color: colors.red,
          ':active': {
            backgroundColor: colors.red,
            border: `2px solid ${colors.red}`,
            color: colors.white,
          },
        }
      : {};
  },
  props => {
    return props.secondary
      ? {
          border: `2px solid ${colors.paleBlue}`,
          backgroundColor: colors.white,
          color: colors.blue,
          ':active': {
            backgroundColor: colors.blue,
            border: `2px solid ${colors.blue}`,
            color: colors.white,
          },
        }
      : {};
  },
  props => {
    return props.disabled
      ? {
          backgroundColor: colors.grey,
        }
      : {};
  },
);

export const Card = styled('div')({
  padding: '16px 12px',
  backgroundColor: colors.white,
  borderRadius: 3,
  boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.1)',
});
