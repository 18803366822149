import React from 'react';

import { Close } from '../icons';
import { colors } from '../constants';
import { Button, Card } from '../forms';
import { Modal, useOnClickOutside } from '../modal';
import { useKeyScanner } from '../scanner';
import { Heading, Text } from '../typography';

export function ConsignmentTransit({ locations }) {
  const [locationCounts, setLocationCounts] = React.useState({
    consignment: 0,
    transit: 0,
  });
  const [history, setHistory] = React.useState([]);

  function fetchLocationCounts() {
    fetch('/app/consignment-location-counts')
      .then(res => res.json())
      .then(result => {
        if (result.error) {
          setLocationCounts({ error: result.error });
          return;
        }

        setLocationCounts(result);
      });
  }

  const errorModal = useErrorModal();

  useKeyScanner({
    onScan: value => {
      fetch(`/scan/${value}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      })
        .then(res => res.json())
        .then(response => {
          if (response.error) {
            if (response.error === 'Not found') {
              errorModal.setError({
                title: 'Pöntun fannst ekki',
                body:
                  'Þessi pöntun fannst ekki í kerfinu, vinsamlegast lesið strikamerkið aftur.',
              });
              setHistory(currentHistory => [
                { type: 'order', error: 'Pöntun fannst ekki', value },
                ...currentHistory,
              ]);
              return;
            }

            if (response.error && response.info) {
              if (
                response.info.expectedCurrentStatus === 'transit' &&
                response.info.actualCurrentStatus === 'consignment'
              ) {
                setHistory(currentHistory => [
                  { type: 'order', error: 'Pöntun þegar lesin', value },
                  ...currentHistory,
                ]);
                return;
              } else if (
                response.info.scannerLocationId &&
                response.info.orderLocationId &&
                response.info.scannerLocationId !==
                  response.info.orderLocationId
              ) {
                const expectedLocation = locations.find(
                  location => location.id === response.info.scannerLocationId,
                );
                const orderLocation = locations.find(
                  location => location.id === response.info.orderLocationId,
                );
                errorModal.setError({
                  title: 'Villa',
                  body: `Þessi pöntun tilheyrir öðrum afhendingarstað. Pöntun á að fara á ${orderLocation.name} í stað ${expectedLocation.name}.`,
                });

                setHistory(currentHistory => [
                  {
                    type: 'order',
                    error: 'Pöntun tilheyrir öðrum afhendingarstað',
                    value,
                  },
                  ...currentHistory,
                ]);
                return;
              }
            }

            errorModal.setError({
              title: 'Villa',
              body: 'Óþekkt villa kom upp. Vinsamlegast reynið aftur.',
            });

            setHistory(currentHistory => [
              { type: 'order', error: 'Óþekkt villa', value },
              ...currentHistory,
            ]);
            return;
          }

          setHistory(currentHistory => [
            { type: 'order', value },
            ...currentHistory,
          ]);

          fetchLocationCounts();
        });
    },
  });

  React.useEffect(() => {
    fetchLocationCounts();
  }, []);

  return (
    <div>
      <errorModal.ModalComponent />
      <Heading>Lestur</Heading>
      <Card
        css={{
          margin: '12px 0 0',
          backgroundColor: 'rgba(64, 64, 157, .2)',
        }}
      >
        Lestu strikamerki...
      </Card>
      <Heading
        css={{
          fontSize: 16,
          color: colors.paleBlue,
          marginTop: 12,
        }}
      >
        Yfirlit
      </Heading>
      <Card css={{ marginTop: 6 }}>
        {locationCounts.error && (
          <Text>Villa: ekki tókst að hlaða stöðu pantana.</Text>
        )}
        <div css={{ display: 'flex', justifyContent: 'space-between' }}>
          <Text>Sendingar á leiðinni:</Text>
          <Text css={{ fontSize: 18, fontWeight: 400 }}>
            {locationCounts.transit || 0}
          </Text>
        </div>
        <div
          css={{
            marginTop: 6,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Text>Sendingar í skáp:</Text>
          <Text css={{ fontSize: 18, fontWeight: 400 }}>
            {locationCounts.consignment || 0}
          </Text>
        </div>
      </Card>
      {history.length > 0 && (
        <div css={{ marginTop: 12 }}>
          <Heading css={{ fontSize: 16, color: colors.paleBlue }}>
            Lestrarsaga
          </Heading>
          <Card
            css={{
              marginTop: 6,
              maxHeight: 100,
              overflow: 'auto',
              'p:not(:last-of-type)': { marginBottom: 6 },
            }}
          >
            {history.map(item => (
              <Text
                key={item}
                css={{ color: item.error ? colors.red : colors.blue }}
              >
                {item.error
                  ? `Villa: ${item.error} (${item.value}).`
                  : item.type === 'order'
                  ? `Pöntun lesin (${item.value}).`
                  : `Las hillu (${item.value}).`}
              </Text>
            ))}
          </Card>
        </div>
      )}
    </div>
  );
}

function useErrorModal() {
  const [error, _setError] = React.useState({
    title: 'Villa',
    body: 'Villa kom upp',
  });
  const [isDialogOpen, showDialog] = React.useState(false);

  function setError({ title, body }) {
    _setError({ title, body });
    showDialog(true);
  }

  function ModalComponent({ onClose }) {
    function closeModal() {
      showDialog(false);
      if (onClose) {
        onClose();
      }
    }

    const innerContainer = React.createRef();
    useOnClickOutside(innerContainer, closeModal);

    return (
      <Modal isOpen={isDialogOpen}>
        <div
          ref={innerContainer}
          css={{
            position: 'relative',
            margin: 20,
            width: '100%',
            padding: '16px 12px',
            backgroundColor: colors.white,
            borderRadius: 5,
            boxShadow: '0 2px 10px 2px rgba(0, 0, 0, .25)',
          }}
        >
          <Heading css={{ color: colors.red, marginRight: 30 }}>
            {error.title}
          </Heading>
          <Close
            width={20}
            height={20}
            color={colors.blue}
            role="button"
            onClick={closeModal}
            styles={{ position: 'absolute', top: 16, right: 12 }}
          />
          <div
            css={{
              marginTop: 14,
              '>*:not(:last-child)': {
                marginBottom: 12,
              },
              h3: {
                fontSize: 14,
              },
            }}
          >
            <div
              css={{
                margin: '0 -12px',
                backgroundColor: colors.paleRed,
                padding: '8px 12px',
              }}
            >
              <Text>{error.body}</Text>
            </div>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Button onClick={closeModal}>Allt í lagi</Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return { ModalComponent, isDialogOpen, showDialog, setError };
}
