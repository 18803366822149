import { AuthForm } from './authenticate';

export function ResetPassword() {
  return (
    <AuthForm
      endpoint="/auth/reset-password"
      title="Glatað lykilorð"
      action="Endurstilla lykilorð"
      form={[
        {
          label: 'Netfang',
          type: 'email',
          placeholder: 'Netfang',
          autoComplete: 'username',
        },
      ]}
      links={[
        {
          url: '/authenticate',
          label: 'Innskráning',
        },
        {
          url: '/register',
          label: 'Nýskráning',
        },
      ]}
    />
  );
}
