import React from 'react';

import { Link } from '../router';
import { colors } from '../constants';
import { Heading, Text } from '../typography';
import { Button, LabelledInput } from '../forms';

export function AuthForm({
  title,
  form,
  action,
  links,
  endpoint,
  message: _message,
  disabled = false,
  callback,
}) {
  const el = React.useRef();
  const [error, setError] = React.useState(null);
  const [message, setMessage] = React.useState(_message);

  function onSubmit(event) {
    event.preventDefault();

    setError(null);
    fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(Object.fromEntries(new FormData(el.current))),
    })
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          setError(response.error);
          return;
        }

        if (el.current) {
          el.current.reset();
        }

        if (response.message) {
          setMessage(response.message);
        }

        if (callback) {
          callback(response);
        }
      });
  }

  return (
    <form
      onSubmit={onSubmit}
      ref={el}
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '24px 36px',
      }}
    >
      <Heading as="h1" css={{ margin: 0, marginBottom: 16 }}>
        {title}
      </Heading>
      {error && (
        <div
          css={{
            backgroundColor: colors.paleRed,
            color: colors.red,
            width: 248,
            padding: '10px 12px 11px',
            marginBottom: 16,
            borderRadius: 3,
          }}
        >
          <Heading as="h2" css={{ fontSize: 16, color: colors.red }}>
            Villa
          </Heading>
          <Text css={{ marginTop: 6 }}>{error}</Text>
        </div>
      )}
      {message && (
        <div
          css={{
            backgroundColor: 'rgba(64,64,157,.3)',
            color: colors.blue,
            width: 248,
            padding: '10px 12px 11px',
            marginBottom: 16,
            borderRadius: 3,
          }}
        >
          <Heading as="h2" css={{ fontSize: 16 }}>
            {message.title}
          </Heading>
          <Text css={{ marginTop: 6 }}>{message.body}</Text>
        </div>
      )}
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          width: 248,
          backgroundColor: '#fff',
          padding: '18px 13px 16px',
          borderRadius: 3,
          boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.1)',
          '>*:not(:last-of-type)': {
            marginBottom: 16,
          },
        }}
      >
        {form.map(
          (
            {
              label,
              type,
              name,
              placeholder,
              autoComplete,
              styles = {},
              ...rest
            },
            i,
          ) => (
            <LabelledInput
              key={type}
              label={label}
              styles={styles}
              inputProps={{
                type,
                placeholder,
                autoComplete,
                name: name || type,
                autoFocus: i === 0,
                disabled,
                ...rest,
              }}
            />
          ),
        )}
        <Button
          disabled={disabled}
          css={{ marginTop: 18, minWidth: 124, alignSelf: 'center' }}
        >
          {action}
        </Button>
      </div>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 16,
          '>*:not(:last-of-type)': {
            marginBottom: 10,
          },
        }}
      >
        {links.map(({ url, label }) => (
          <Link key={url} to={url}>
            {label}
          </Link>
        ))}
      </div>
    </form>
  );
}

export function Authenticate({ history, location, setState }) {
  function onResponseCallback(response) {
    setState(currentState => ({
      env: response.env || currentState.env,
      store: response.store || currentState.store,
      stores: response.stores || currentState.stores,
      locations: response.locations || currentState.locations,
      user: response.user,
    }));

    history.replace('/');
  }

  const params = new URLSearchParams(location.search);
  const didChangePassword = !!params.get('passwordChanged');

  return (
    <AuthForm
      endpoint="/auth/authenticate"
      callback={onResponseCallback}
      title="Innskráning"
      action="Innskrá"
      message={
        didChangePassword && {
          title: 'Lykilorði breytt',
          body:
            'Lykilorði þínu hefur verið breytt; vinsamlegast skráðu þig inn.',
        }
      }
      form={[
        {
          label: 'Netfang',
          type: 'email',
          placeholder: 'Netfang',
          autoComplete: 'username',
        },
        {
          label: 'Lykilorð',
          type: 'password',
          placeholder: 'Lykilorð',
          autoComplete: 'current-password',
        },
      ]}
      links={[
        {
          url: '/reset-password',
          label: 'Gleymt lykilorð',
        },
        {
          url: '/register',
          label: 'Nýskráning',
        },
      ]}
    />
  );
}
