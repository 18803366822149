import { AuthForm } from './authenticate';

export function Register(props) {
  const params = new URLSearchParams(props.location.search);
  const token = params.get('token');

  const message = !token && {
    title: 'Boðshlekk vantar',
    body:
      'Þú getur aðeins skráð nýjan aðgang með boðshlekki. Athugaðu pósthólfið þitt.',
  };

  return (
    <AuthForm
      endpoint="/auth/register"
      title="Nýskráning"
      message={message}
      action="Nýskrá"
      disabled={!token}
      form={[
        {
          type: 'hidden',
          name: 'token',
          value: token,
          styles: { display: 'none' },
        },
        {
          label: 'Netfang',
          type: 'email',
          placeholder: 'Netfang',
          autoComplete: 'username',
          autoFocus: true,
        },
        {
          label: 'Nafn',
          type: 'text',
          name: 'name',
          placeholder: 'Nafn',
        },
        {
          label: 'Lykilorð',
          type: 'password',
          placeholder: 'Lykilorð',
          autoComplete: 'new-password',
        },
      ]}
      links={[
        {
          url: '/reset-password',
          label: 'Gleymt lykilorð',
        },
        {
          url: '/authenticate',
          label: 'Innskráning',
        },
      ]}
    />
  );
}
