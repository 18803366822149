import React from 'react';

import { useInterval } from './my-order';
import { Modal, useOnClickOutside } from '../modal';
import { fonts, colors } from '../constants';
import { Button } from '../forms';
import { Heading, Text } from '../typography';
import { Close } from '../icons';
import { useKeyScanner } from '../scanner';

function useLocationCounts() {
  const [locationCounts, setLocationCounts] = React.useState(null);
  const [totals, setTotals] = React.useState({ unreadCount: 0, readCount: 0 });

  function fetchLocationCounts() {
    fetch('/app/transit-location-counts')
      .then(res => res.json())
      .then(results => {
        const locationCounts = results.map(result => ({
          id: result.id,
          name: result.name,
          unreadCount: result.initial,
          readCount: result.transit,
        }));
        const unreadCount = results.reduce((acc, item) => {
          acc += item.initial;
          return acc;
        }, 0);
        const readCount = results.reduce((acc, item) => {
          acc += item.transit;
          return acc;
        }, 0);

        setLocationCounts(locationCounts);
        setTotals({ unreadCount, readCount });
      });
  }

  React.useEffect(() => {
    fetchLocationCounts();
  }, []);

  useInterval(() => {
    // Only fetch when the browser/tab is active
    if (!document.hidden) {
      fetchLocationCounts();
    }
  }, 5000);

  return { locationCounts, totals, fetchLocationCounts };
}

export function useErrorModal() {
  const [isDialogOpen, showDialog] = React.useState(false);

  const innerContainer = React.createRef();
  useOnClickOutside(innerContainer, () => showDialog(false));

  function ModalComponent() {
    return (
      <Modal isOpen={isDialogOpen}>
        <div
          ref={innerContainer}
          css={{
            position: 'relative',
            margin: 20,
            padding: '16px 12px',
            backgroundColor: colors.white,
            borderRadius: 5,
            boxShadow: '0 2px 10px 2px rgba(0, 0, 0, .25)',
          }}
        >
          <Heading css={{ color: colors.red }}>Villa</Heading>
          <Close
            width={20}
            height={20}
            color={colors.blue}
            role="button"
            onClick={() => showDialog(false)}
            styles={{ position: 'absolute', top: 16, right: 12 }}
          />
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 14,
              '>*:not(:last-child)': {
                marginBottom: 12,
              },
              h3: {
                fontSize: 14,
              },
            }}
          >
            <Text>
              Ekki tókst að lesa strikamerki eða pöntun er ekki skráð.
            </Text>
            <Button
              css={{ alignSelf: 'flex-end' }}
              onClick={() => showDialog(false)}
              danger
            >
              Allt í lagi
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  return { ModalComponent, isDialogOpen, showDialog };
}

function useInfoModal({ submit }) {
  const [isDialogOpen, showDialog] = React.useState(false);

  const innerContainer = React.createRef();
  useOnClickOutside(innerContainer, () => showDialog(false));

  function ModalComponent({ lastScan }) {
    return (
      lastScan && (
        <Modal isOpen={isDialogOpen}>
          <div
            ref={innerContainer}
            css={{
              position: 'relative',
              margin: 20,
              padding: '16px 12px',
              width: '100%',
              backgroundColor: colors.white,
              borderRadius: 5,
              boxShadow: '0 2px 10px 2px rgba(0, 0, 0, .25)',
            }}
          >
            <Heading>
              {lastScan.status === 'initial'
                ? 'Pöntun lesin'
                : 'Pöntun þegar lesin'}
            </Heading>
            <Close
              width={20}
              height={20}
              color={colors.blue}
              role="button"
              onClick={() => showDialog(false)}
              styles={{ position: 'absolute', top: 16, right: 12 }}
            />
            <div
              css={{
                marginTop: 14,
                marginBottom: 18,
                '>*:not(:last-child)': {
                  marginBottom: 12,
                },
                h3: {
                  fontSize: 14,
                },
              }}
            >
              <div
                css={{
                  margin: '0 -12px',
                  backgroundColor: 'rgba(64, 64, 157, .2)',
                  padding: '8px 12px',
                }}
              >
                <Heading as="h3" css={{ color: '#5f5f5f' }}>
                  Áfangastaður
                </Heading>
                <Text
                  css={{
                    fontWeight: 400,
                    fontSize: 18,
                    margin: '3px 0',
                  }}
                >
                  {lastScan.locationName}
                </Text>
              </div>
              <div>
                <Heading as="h3">Vörur</Heading>
                <Text>
                  {lastScan.products.map(product => product.name).join(', ')}
                </Text>
              </div>
            </div>
            {lastScan.status &&
              lastScan.status !== 'initial' &&
              lastScan.status !== 'transit' && (
                <Text css={{ color: colors.red, marginBottom: 18 }}>
                  Ath. pöntun er ekki í vöruhúsi né í bíl (staða:{' '}
                  {lastScan.status === 'consignment'
                    ? 'á áfangastað'
                    : 'afhent'}
                  ).
                </Text>
              )}
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Button
                css={{ height: 48, fontSize: 18, borderRadius: 3 }}
                onClick={submit}
                danger={lastScan.status === 'transit'}
                disabled={
                  lastScan.status !== 'initial' && lastScan.status !== 'transit'
                }
              >
                {lastScan.status === 'initial' ? 'Taka í bíl' : 'Taka úr bíl'}
              </Button>
            </div>
          </div>
        </Modal>
      )
    );
  }

  return { ModalComponent, isDialogOpen, showDialog };
}

export function Transit() {
  const ref = React.useRef();
  function setFetchCallback(fn) {
    ref.current = fn;
  }

  const [lastScan, setLastScan] = React.useState(null);

  // Change the order's status from initial -> transit or from transit -> initial,
  // depending on its current status.
  function scanChangeStatus({ barcode, status }) {
    fetch(`/scan/${barcode}`, {
      method: status === 'initial' ? 'POST' : 'DELETE',
    })
      .then(res => res.json())
      .then(({ error }) => {
        if (error) {
          errorModal.showDialog(true);
          return;
        }

        if (ref.current) {
          ref.current();
        }
      });
  }

  const errorModal = useErrorModal();
  const infoModal = useInfoModal({
    submit: () => {
      infoModal.showDialog(false);

      scanChangeStatus({ barcode: lastScan.barcode, status: lastScan.status });
    },
  });

  useKeyScanner({
    onScan: value => {
      errorModal.showDialog(false);
      infoModal.showDialog(true);

      fetch(`/scan/${value}`)
        .then(res => res.json())
        .then(({ error, ...order }) => {
          if (error) {
            setLastScan(null);
            errorModal.showDialog(true);
            return;
          }

          setLastScan(order);

          infoModal.showDialog(true);
        });
    },
  });

  return (
    <>
      <LocationStatusTable setFetch={setFetchCallback} />
      {errorModal.isDialogOpen ? (
        <errorModal.ModalComponent />
      ) : (
        <infoModal.ModalComponent lastScan={lastScan} />
      )}
    </>
  );
}

function LocationStatusTable({ setFetch }) {
  const { locationCounts, totals, fetchLocationCounts } = useLocationCounts();

  React.useEffect(() => {
    setFetch(fetchLocationCounts);
  }, []);

  return (
    <table css={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead
        css={{ tr: { textAlign: 'right' }, 'th,td': { padding: '6px 8px' } }}
      >
        <tr
          css={{
            th: {
              fontSize: 18,
              color: colors.paleBlue,
              fontFamily: fonts.display,
              fontWeight: 400,
            },
          }}
        >
          <th />
          <th css={{ width: 50 }}>Ólesið</th>
          <th css={{ width: 50 }}>Lesið</th>
        </tr>
        <tr css={{ fontWeight: 500 }}>
          <td></td>
          <td>{totals.unreadCount}</td>
          <td>{totals.readCount}</td>
        </tr>
      </thead>
      <tbody
        css={{
          borderTop: '2px solid rgba(64, 64, 157, .5)',
          'tr:nth-of-type(even)': {
            backgroundColor: 'rgba(64, 64, 157, .2)',
          },
          td: {
            padding: '6px 8px',
          },
          'td:not(:first-of-type)': { textAlign: 'right' },
        }}
      >
        {locationCounts === null ? (
          <tr>
            <td>Hleð...</td>
          </tr>
        ) : locationCounts.length === 0 ? (
          <tr>
            <td>Engar niðurstöður.</td>
          </tr>
        ) : (
          locationCounts.map(location => (
            <tr key={location.id}>
              <td>{location.name}</td>
              <td>{location.unreadCount}</td>
              <td>{location.readCount}</td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
}
