import React from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  Link as RouterLink,
} from 'react-router-dom';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';

import { colors } from './constants';
import { Authenticate } from './pages/authenticate';
import { ChangePassword } from './pages/change-password';
import { Barcode } from './pages/barcode';
import { Docs } from './pages/docs';
import { Navigation } from './navigation';
import { NotFound } from './pages/not-found';
import { Register } from './pages/register';
import { ResetPassword } from './pages/reset-password';
import { Transit } from './pages/transit';
import { Consignment } from './pages/consignment';
import { MyOrder } from './pages/my-order';
import { Admin } from './pages/admin';
import { SuperAdmin } from './pages/super-admin';

// Helper to redirect if the provided condition is false.
function ConditionalRoute({ cond, redirectTo, render, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => (cond ? render(props) : <Redirect to={redirectTo} />)}
    />
  );
}

const anchorStyles = {
  outline: 'none',
  color: colors.blue,
  ':active': {
    color: colors.teal,
  },
  ':focus': {
    boxShadow: `0 0 0 2px ${colors.teal}`,
  },
};

// For internal links
export const Link = styled(RouterLink)(anchorStyles);

// For external links
export const Anchor = styled('a')(anchorStyles);

const load = keyframes`
  0% {
    opacity: 0;
    transform: translate(-7px, 0);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export function AnimateLoad(Component) {
  return props => (
    <div
      css={{
        animation: `${load} .36s ease`,
        height: '100%',
      }}
    >
      <Component {...props} />
    </div>
  );
}

export function Router({ state }) {
  // Helper to pass down application state from the parent into the route component.
  function renderRoute(Component) {
    return props => <Component {...props} {...state} />;
  }

  function createRouteHelper(cond, redirectTo) {
    return function RouteHelper({
      path,
      component: Component,
      cond: additionalCond = true,
      ...rest
    }) {
      return (
        <ConditionalRoute
          path={path}
          cond={cond && additionalCond}
          redirectTo={redirectTo}
          render={renderRoute(Component)}
          {...rest}
        />
      );
    };
  }

  // Used for routes that are only supposed to be accessible to logged-in users,
  // otherwise redirecting to the log in page.
  const AuthenticatedRoute = createRouteHelper(!!state.user, '/authenticate');

  // Used for routes that are only supposed to be accessible to logged-out users,
  // otherwise redirecting to the home page.
  const UnauthenticatedRoute = createRouteHelper(!state.user, '/');

  return (
    <BrowserRouter>
      <Route
        render={props =>
          !props.location.pathname.startsWith('/pontun') && (
            <Navigation
              user={state.user}
              styles={{ position: 'fixed', top: 0, left: 0, right: 0 }}
            />
          )
        }
      />
      <main
        css={{
          position: 'fixed',
          top: 50,
          left: 0,
          right: 0,
          bottom: 0,
          maxHeight: 'calc(100vh - 50px)',
          overflow: 'auto',
          padding: '16px 12px',
          '>div': {
            margin: '0 auto',
            maxWidth: 1200,
          },
        }}
      >
        <Switch>
          <Route exact path="/docs" render={renderRoute(Docs)} />

          {!state.user && (
            <Route
              exact
              path="/"
              render={() => <Redirect to="/authenticate" />}
            />
          )}

          <Route exact path="/barcode/:value?" component={Barcode} />

          <UnauthenticatedRoute
            exact
            path="/authenticate"
            component={AnimateLoad(Authenticate)}
          />
          <UnauthenticatedRoute
            exact
            path="/register"
            component={AnimateLoad(Register)}
          />
          <UnauthenticatedRoute
            exact
            path="/reset-password"
            component={AnimateLoad(ResetPassword)}
          />
          <UnauthenticatedRoute
            exact
            path="/change-password"
            component={AnimateLoad(ChangePassword)}
          />
          <UnauthenticatedRoute
            exact
            path="/pontun/:token"
            component={AnimateLoad(MyOrder)}
          />

          {/* Render a different component based on the type of user. */}
          {state.user &&
            (state.user.role === 'super-admin' ? (
              <AuthenticatedRoute
                exact
                path="/"
                component={AnimateLoad(SuperAdmin)}
              />
            ) : state.user.role === 'admin' ? (
              <AuthenticatedRoute
                exact
                path="/"
                component={AnimateLoad(Admin)}
              />
            ) : state.user.role === 'transit' ? (
              <AuthenticatedRoute
                exact
                path="/"
                component={AnimateLoad(Transit)}
              />
            ) : state.user.role === 'consignment' ? (
              <AuthenticatedRoute
                path="/"
                component={AnimateLoad(Consignment)}
              />
            ) : null)}

          <Route component={AnimateLoad(NotFound)} />
        </Switch>
      </main>
    </BrowserRouter>
  );
}
