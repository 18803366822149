import React from 'react';

import { colors } from '../constants';
import { Heading, Text } from '../typography';
import { Button, Card, LabelledInput } from '../forms';

const noOp = () => {};

const spacing = 12;
const defaultFormValue = {
  email: '',
  name: '',
  password: '',
  role: null,
  storeId: null,
  locationId: null,
};
export function SuperAdmin({ stores, locations }) {
  const [error, setError] = React.useState(null);
  const [form, setForm] = React.useState(defaultFormValue);

  function onChange(event) {
    const { target } = event;
    setForm(currentValue => ({
      ...currentValue,
      [target.name]: target.value,
    }));
  }

  function inviteUser() {
    if (!form.email) {
      setError('Skráðu netfang notanda');
      return;
    }

    if (!form.role) {
      setError('Veldu tegund notanda');
      return;
    }

    if (form.role === 'admin' && !form.storeId) {
      setError('Veldu verslun notanda');
      return;
    }

    if (form.role === 'consignment' && !form.locationId) {
      setError('Veldu afhendingarstað notanda');
      return;
    }

    if (form.password && form.password.length < 8) {
      setError('Lykilorð skal vera átta stafir að lengd eða lengra');
      return;
    }

    setError(null);

    let error = false;
    fetch('/admin/invite', {
      method: 'POST',
      body: JSON.stringify(form),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        }

        error = true;
        return res.text();
      })
      .then(response => {
        if (error) {
          let errorMessage;
          try {
            const res = JSON.parse(response);
            errorMessage = res.error || res.message;
          } catch (err) {}

          setError(errorMessage || 'Óþekkt villa');
          return;
        }

        if (response.error) {
          setError(response.error);
          return;
        }

        setForm(defaultFormValue);
      });
  }

  return (
    <>
      <Heading>Umsjónarsíða Dropps</Heading>
      <Card css={{ marginTop: spacing }}>
        <Heading as="h2" css={{ fontWeight: 400 }}>
          Nýr notandi
        </Heading>
        {error && (
          <div
            css={{
              marginTop: spacing,
              padding: spacing,
              border: `1px solid ${colors.red}`,
              borderRadius: 5,
            }}
          >
            <Heading as="h2" css={{ color: colors.red }}>
              Villa
            </Heading>
            <Text css={{ color: colors.red }}>{error}</Text>
          </div>
        )}
        <div css={{ '>*': { marginTop: spacing } }}>
          <LabelledInput
            label="Netfang"
            inputProps={{
              onChange,
              value: form.email,
              type: 'email',
              name: 'email',
              placeholder: 'Netfang',
            }}
          />
          <LabelledInput
            label="Lykilorð"
            inputProps={{
              onChange,
              value: form.password,
              type: 'text',
              name: 'password',
              autoComplete: 'off',
              placeholder:
                'Lykilorð notanda (valkvætt - þarf ekki að staðfesta netfang ef stillt)',
            }}
          />
          <LabelledInput
            label="Nafn"
            inputProps={{
              onChange,
              value: form.nafn,
              type: 'name',
              name: 'name',
              placeholder: 'Nafn',
            }}
          />
          <fieldset
            css={{
              border: 'none',
              margin: 0,
              padding: 0,
              marginTop: spacing,
              legend: {
                fontSize: 14,
                fontWeight: 400,
                marginBottom: 8,
              },
              '>label': { marginLeft: spacing / 2, marginRight: spacing },
              '>label>span': { marginLeft: spacing / 2 },
            }}
          >
            <legend>Tegund notanda</legend>
            <label>
              <input
                type="radio"
                name="role"
                value="transit"
                onChange={onChange}
                checked={form.role === 'transit'}
              />
              <span>Bílstjórn (transit)</span>
            </label>
            <label>
              <input
                type="radio"
                name="role"
                value="admin"
                onChange={onChange}
                checked={form.role === 'admin'}
              />
              <span>Verslunarumsjón (admin)</span>
            </label>
            <label>
              <input
                type="radio"
                name="role"
                value="consignment"
                onChange={onChange}
                checked={form.role === 'consignment'}
              />
              <span>Afhending (consignment)</span>
            </label>
          </fieldset>
          {form.role === 'admin' ? (
            <div css={{ marginTop: spacing }}>
              <label>
                <span css={{ fontSize: 14, fontWeight: 400 }}>Verslun</span>
                <select
                  css={{ marginTop: 8, display: 'block' }}
                  onChange={event => {
                    const { selectedIndex } = event.target;
                    if (selectedIndex >= 1) {
                      setForm(currentValue => ({
                        ...currentValue,
                        storeId: stores[selectedIndex - 1].id,
                      }));
                    }
                  }}
                >
                  <option default value="label">
                    Veldu verslun
                  </option>
                  {stores.map(store => (
                    <option key={store.id} value={store.id}>
                      {store.name}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          ) : (
            form.role === 'consignment' && (
              <div css={{ marginTop: spacing }}>
                <label>
                  <span css={{ fontSize: 14, fontWeight: 400 }}>
                    Afhendingarstaður
                  </span>
                  <select
                    css={{ marginTop: 8, display: 'block' }}
                    onChange={event => {
                      const { selectedIndex } = event.target;
                      if (selectedIndex >= 1) {
                        setForm(currentValue => ({
                          ...currentValue,
                          locationId: locations[selectedIndex - 1].id,
                        }));
                      }
                    }}
                  >
                    <option default value="label">
                      Veldu staðsetningu
                    </option>
                    {locations.map(location => (
                      <option key={location.id} value={location.id}>
                        {location.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
            )
          )}
          <div css={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
            <Button onClick={inviteUser}>Bjóða notanda</Button>
            <Text css={{ marginLeft: spacing, color: colors.grey }}>
              Notandinn mun koma til með að þurfa að opna póstinn og velja
              lykilorð.
            </Text>
          </div>
        </div>
      </Card>
    </>
  );
}
