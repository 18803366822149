import React from 'react';

import { Anchor } from '../router';
import { colors } from '../constants';
import { Heading, Text } from '../typography';
import { Button, Card, LabelledInput } from '../forms';

const noOp = () => {};

export function Admin({ store }) {
  const [isKeyVisible, setIsKeyVisible] = React.useState(false);
  const [isTokenVisible, setIsTokenVisible] = React.useState(false);

  // Use a regular input, just ignore keystrokes. This allows the user
  // to select the text normally without editing the value (disabled inputs
  // behave differently depending on the environment).
  const onChange = noOp;

  // Double clicking in the input may not select the whole contents, and in
  // the case of the base 64 encoded string this may not be obvious.
  function onClick(event) {
    event.target.select();
  }

  function toggleShowSecretKey() {
    setIsKeyVisible(isVisible => !isVisible);
  }

  return (
    <>
      <Heading>Admin síða {store.name}</Heading>
      <Card css={{ marginTop: 12 }}>
        <Heading as="h2" css={{ fontWeight: 400 }}>
          Auðkennislyklar
        </Heading>
        <Text css={{ marginTop: 18, color: '#333' }}>
          Auðkennislykla má nota til að tengjast forritaskilum Dropps eins og
          lýst er í{' '}
          <Anchor
            href="https://app.dropp.is/docs#authorization"
            rel="noopener noreferrer"
            target="_blank"
          >
            skjölun okkar
          </Anchor>
          . Athugið að auðkennislykillinn veitir fullan aðgang að forritaskilum
          okkar gagnvart {store.name} og hann ætti aðeins að nota í treystu
          umhverfi, t.d. vefþjónum en <strong>aldrei í framendakóða</strong>.
        </Text>

        <div css={{ marginTop: 18, '>*': { marginTop: 12 } }}>
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              '>*': { flex: 1 },
            }}
          >
            <LabelledInput
              label={
                <span>
                  Auðkenni{' '}
                  <span css={{ color: colors.grey }}>(e. client Id)</span>
                </span>
              }
              inputProps={{ onChange, onClick, value: store.id }}
              styles={{ color: colors.paleBlue, marginRight: 12 }}
            />
            <LabelledInput
              label={
                <span>
                  Auðkennislykill{' '}
                  <span css={{ color: colors.grey }}>(e. secret key)</span>
                </span>
              }
              inputProps={{
                onChange,
                onClick,
                value: store.secretKey,
                type: isKeyVisible ? 'text' : 'password',
              }}
              styles={{ color: colors.paleBlue }}
            />
            <Button
              secondary
              onClick={() => setIsKeyVisible(isVisible => !isVisible)}
              css={{
                height: 30,
                marginTop: 29,
                width: 60,
                maxWidth: 60,
                marginLeft: 12,
              }}
            >
              {isKeyVisible ? 'Fela' : 'Birta'}
            </Button>
          </div>
          <div css={{ display: 'flex' }}>
            <LabelledInput
              label={
                <span>
                  Sameinað auðkenni{' '}
                  <span css={{ color: colors.grey }}>
                    (e. basic auth token)
                  </span>
                </span>
              }
              inputProps={{
                onChange,
                onClick,
                value: btoa(`${store.id}:${store.secretKey}`),
                type: isTokenVisible ? 'text' : 'password',
              }}
              styles={{ flex: 1, color: colors.paleBlue }}
            />
            <Button
              secondary
              onClick={() => setIsTokenVisible(isVisible => !isVisible)}
              css={{
                height: 30,
                marginTop: 29,
                width: 60,
                maxWidth: 60,
                marginLeft: 12,
              }}
            >
              {isTokenVisible ? 'Fela' : 'Birta'}
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
}
