import React from 'react';
import { Global } from '@emotion/core';

import { injectModalsContainer } from './modal';
import { Router } from './router';
import { colors } from './constants';

// Do this as soon as possible, before rendering the app.
injectModalsContainer();

export function App({ initialState }) {
  const [state, _setState] = React.useState(initialState);

  function setState(newState) {
    if (typeof newState === 'function') {
      return _setState(newState);
    }

    return _setState(currentState => ({ ...currentState, ...newState }));
  }

  return (
    <>
      <Global
        styles={{
          'html, body': {
            fontSize: 16,
            fontFamily: 'GT Eesti Pro Text, "sans-serif"',
            fontWeight: 300,
            color: colors.blue,
            backgroundColor: colors.background,
            height: '100%',
            minHeight: '100vh',
          },
          html: {
            boxSizing: 'border-box',
          },
          '*, *:before, *:after': {
            boxSizing: 'inherit',
          },
        }}
      />
      <Router state={{ ...state, setState }} />
    </>
  );
}
