import React from 'react';

/**
 * Keystroke barcode scanner
 *
 * Sets up a global keydown event listener and checks for a prefix
 * configured in the barcode scanner in order to start scanning.
 * Once a suffix is detected, the provided onScan function is
 * called with the scanned value.
 *
 * Any events containing a multicharacter string (such as "Shift")
 * will be ignored, if detected.
 *
 * The prefix and suffix configuration in the Zebra TC25 device can
 * be found by opening DataWedge, Profile0 (default), Basic data
 * formatting.
 */
const prefix = '#ppp$';
const suffix = '#sss$';
export function useKeyScanner({ onScan }) {
  // Mutable refs used over state in order to avoid
  // affecting component renders.
  const isScanning = React.useRef(false);
  const buffer = React.useRef('');
  const currentValue = React.useRef('');

  React.useEffect(() => {
    function onKeyDown(event) {
      // Sometimes we will receive keydown events for keys such as Shift,
      // which we're not interested in. We only want single-character keys.
      if (event.key.length > 1) return;

      // We have already matched the prefix before this key, so either this
      // key value is part of the value being scanned or the suffix used
      // to demark the end of the value.
      if (isScanning.current) {
        // Keep track of all values scanned from the prefix, even if it is
        // part of the suffix, we will remove it later.
        currentValue.current = currentValue.current + event.key;

        // Check whether the current key matches the current position of the
        // suffix being tracked.
        if (event.key === suffix[buffer.current.length]) {
          // The current value is part of the suffix, whether it is the first,
          // last, or any character in it (it must be serial).
          buffer.current = buffer.current + event.key;
        } else if (event.key === suffix[0]) {
          // Special case - the key does not match the current index of the
          // suffix already being tracked, but it  may still be part of the
          // suffix. For example, if the scanned barcode is "Example#" (note
          // the last character), and the prefix and suffix are "#ppp$" and
          // "#sss$" respectively, the scanner will emit the string
          // "#ppp$Example##sss$". In this case, the program will reach the
          // first "#" in "Example#" and start counting it as the suffix.
          // Once the next character is read, also an "#", the program needs
          // to reset to the first character of the suffix, since it doesn't
          // match "s" (the 1th character in the suffix), but the next key
          // event may very well be an "s".
          buffer.current = event.key;
        } else {
          // The current key does not match the suffix; reset the buffer.
          buffer.current = '';
        }

        // We've matched the suffix, submit the value and reset the scanner.
        if (buffer.current === suffix) {
          onScan(currentValue.current.slice(0, -suffix.length));

          // Stop scanning.
          isScanning.current = false;
          buffer.current = '';
          currentValue.current = '';
        }
      } else {
        if (event.key === prefix[buffer.current.length]) {
          buffer.current = buffer.current + event.key;

          if (buffer.current === prefix) {
            // Start scanning.
            isScanning.current = true;

            // Prepare it for the suffix.
            buffer.current = '';
          }
        } else {
          // Doesn't match, break out.
          buffer.current = '';
        }
      }
    }

    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, [onScan]);
}
