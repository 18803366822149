import React from 'react';

import { colors } from '../constants';
import { Button, Card } from '../forms';
import { Modal, useOnClickOutside } from '../modal';
import { Heading, Text } from '../typography';
import { useKeyScanner } from '../scanner';
import { Close } from '../icons';

const errors = {
  notFound: {
    title: 'Pöntun fannst ekki',
    body: 'Þessi pöntun fannst ekki.',
  },
  unknown: {
    title: 'Óþekkt villa',
    body: 'Óþekkt villa kom upp, vinsamlegast reyndu aftur.',
  },
  alreadyDelivered: {
    title: 'Pöntun afhent',
    body: 'Þessi pöntun hefur þegar verið afhent.',
  },
  incorrectBarcode: {
    title: 'Rangt strikamerki',
    body: 'Þetta virðist ekki vera réttur pakki, prófaðu annan pakka.',
  },
};

export function ConsignmentCustomer({ history }) {
  const { ModalComponent, setError, showDialog } = useErrorModal();
  const [step, setStep] = React.useState(0);
  const [order, setOrder] = React.useState(null);

  useKeyScanner({
    onScan: value => {
      if (step === 0) {
        fetch(`/scan/qr/${value}`)
          .then(res => res.json())
          .then(response => {
            if (response.error === 'Not found') {
              return setError(errors.notFound);
            }

            if (response.error) {
              return setError(errors.unknown);
            }

            if (response.status === 'delivered') {
              return setError(errors.alreadyDelivered);
            }

            setOrder({ ...response, qrCode: value });
            setStep(1);
          })
          .catch(err => {
            setError(errors.unknown);

            // Rethrow to track the error in Sentry.
            throw err;
          });
      } else if (step === 1) {
        fetch('/scan/verify/codes', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ qrCode: order.qrCode, barcode: value }),
        })
          .then(res => res.json())
          .then(response => {
            if (response.error === 'Not found') {
              return setError(errors.incorrectBarcode);
            }

            if (response.error) {
              return setError(errors.unknown);
            }

            showDialog(false);
            setStep(2);
          })
          .catch(err => {
            setError(errors.unknown);

            // Rethrow to track the error in Sentry.
            throw err;
          });
      }
    },
  });

  return (
    <div>
      <ModalComponent />
      <Heading>Afhending</Heading>
      <div css={{ '>div': { marginTop: 12 } }}>
        <StepOne isActive={step === 0} isDone={step > 0} />
        <StepTwo isActive={step === 1} isDone={step > 1} order={order} />
        <StepThree isActive={step === 2} goBack={() => history.push('/')} />
      </div>
    </div>
  );
}

function StepOne({ isActive, isDone }) {
  const [qrCode, setQrCode] = React.useState(null);

  return (
    <div>
      <Heading
        css={{
          fontSize: 16,
          color: isDone ? colors.green : isActive ? colors.paleBlue : '#909090',
        }}
      >
        1. Lestu QR kóða viðskiptavinar
      </Heading>
      {isActive && (
        <Card css={{ marginTop: 6 }}>
          <Text css={{ color: colors.paleBlue, fontSize: 14, marginBottom: 6 }}>
            Beindu skanna að QR kóða.
          </Text>
        </Card>
      )}
    </div>
  );
}

function StepTwo({ isActive, isDone, order }) {
  return (
    <div>
      <Heading
        css={{
          fontSize: 16,

          color: isDone ? colors.green : isActive ? colors.paleBlue : '#909090',
        }}
      >
        2. Finndu pöntun og lestu strikamerkið
      </Heading>
      {isActive && (
        <Card css={{ marginTop: 6 }}>
          <div>
            <Heading
              css={{ fontSize: 16, color: colors.paleBlue, marginBottom: 6 }}
            >
              Vörur í pöntun
            </Heading>
            <Text>
              {order.products.map(product => product.name).join(', ')}
            </Text>
          </div>
          <div css={{ marginTop: 12 }}>
            <Heading
              css={{ fontSize: 16, color: colors.paleBlue, marginBottom: 6 }}
            >
              Viðskiptavinur
            </Heading>
            <Text>{order.customer.name}</Text>
          </div>
          <Text css={{ marginTop: 12, fontWeight: 400 }}>
            Lestu strikamerki pöntunar.
          </Text>
        </Card>
      )}
    </div>
  );
}

function StepThree({ isActive, goBack }) {
  return (
    <div>
      <Heading
        css={{ fontSize: 16, color: isActive ? colors.paleBlue : '#909090' }}
      >
        3. Afhentu pöntun
      </Heading>
      {isActive && (
        <Card css={{ marginTop: 6 }}>
          <Text>
            Afhending pöntunar staðfest, vinsamlegast afhentu pakkann.
          </Text>
          <Button
            secondary
            onClick={goBack}
            css={{ marginTop: 12, height: 30, padding: '0 12px' }}
          >
            Loka
          </Button>
        </Card>
      )}
    </div>
  );
}

function useErrorModal() {
  const [error, _setError] = React.useState({
    title: 'Villa',
    body: 'Villa kom upp',
  });
  const [isDialogOpen, showDialog] = React.useState(false);

  function setError({ title, body }) {
    _setError({ title, body });
    showDialog(true);
  }

  function ModalComponent({ onClose }) {
    function closeModal() {
      showDialog(false);
      if (onClose) {
        onClose();
      }
    }

    const innerContainer = React.createRef();
    useOnClickOutside(innerContainer, closeModal);

    return (
      <Modal isOpen={isDialogOpen}>
        <div
          ref={innerContainer}
          css={{
            position: 'relative',
            margin: 20,
            width: '100%',
            padding: '16px 12px',
            backgroundColor: colors.white,
            borderRadius: 5,
            boxShadow: '0 2px 10px 2px rgba(0, 0, 0, .25)',
          }}
        >
          <Heading css={{ color: colors.red, marginRight: 30 }}>
            {error.title}
          </Heading>
          <Close
            width={20}
            height={20}
            color={colors.blue}
            role="button"
            onClick={closeModal}
            styles={{ position: 'absolute', top: 16, right: 12 }}
          />
          <div
            css={{
              marginTop: 14,
              '>*:not(:last-child)': {
                marginBottom: 12,
              },
              h3: {
                fontSize: 14,
              },
            }}
          >
            <div
              css={{
                margin: '0 -12px',
                backgroundColor: colors.paleRed,
                padding: '8px 12px',
              }}
            >
              <Text>{error.body}</Text>
            </div>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Button onClick={closeModal}>Allt í lagi</Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return { ModalComponent, isDialogOpen, showDialog, setError };
}
