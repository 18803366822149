import styled from '@emotion/styled';

import { colors, fonts } from './constants';

const tagCss = {
  h1: {
    fontSize: 20,
    fontWeight: 400,
    color: colors.blue,
  },
  h2: {
    fontSize: 18,
    fontWeight: 300,
    color: colors.paleBlue,
  },
  h3: {
    fontSize: 16,
    fontWeight: 300,
    color: colors.grey,
  },
};

export const Heading = styled('h1')(
  {
    fontFamily: fonts.display,
    margin: 0,
  },
  props => tagCss[props.as || 'h1'],
);

export const Text = styled('p')({
  fontFamily: fonts.text,
  fontSize: 16,
  margin: 0,
});
