import React from 'react';
import { createPortal } from 'react-dom';
import { keyframes } from '@emotion/core';

import { zIndices } from './constants';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export function injectModalsContainer() {
  const container = document.createElement('div');
  container.id = 'modal-root';
  document.body.appendChild(container);
}

export function Modal({ isOpen, children }) {
  return isOpen
    ? createPortal(
        <div
          css={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.25)',
            animation: `${fadeIn} .36s ease`,
            zIndex: zIndices.modal,
          }}
        >
          {children}
        </div>,
        document.getElementById('modal-root'),
      )
    : null;
}

export function useOnClickOutside(ref, handler) {
  React.useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}
