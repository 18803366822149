import React from 'react';

import { Button } from '../forms';

const ReactBarcode = React.lazy(() => import('react-barcode'));
const QRCode = React.lazy(() => import('../qr-code'));

export function Barcode({ match }) {
  const [barcode, setBarcode] = React.useState(match.params.value || 'Example');
  const [qrCode, setQrCode] = React.useState(match.params.value || 'Example');
  const [isBarcodeVisible, setIsBarcodeVisible] = React.useState(true);
  const [isQrCodeVisible, setIsQrCodeVisible] = React.useState(true);

  return (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <h1>Code 128 barcode</h1>
        <div css={{ marginTop: 20, marginBottom: 20 }}>
          {isBarcodeVisible && (
            <React.Suspense fallback={null}>
              <ReactBarcode value={barcode} />
            </React.Suspense>
          )}
        </div>
        <input
          type="text"
          placeholder="Enter a barcode value"
          onChange={event => setBarcode(event.target.value)}
          value={barcode}
          css={{ maxWidth: 200 }}
          autoFocus
        />
        <Button onClick={() => setIsBarcodeVisible(state => !state)}>
          {isBarcodeVisible ? 'Hide' : 'Show'}
        </Button>
      </div>
      <div>
        <h1>QR code</h1>
        <div css={{ marginTop: 20, marginBottom: 20 }}>
          {isQrCodeVisible && (
            <React.Suspense fallback={null}>
              <QRCode
                code={qrCode}
                size={200}
                styles={{ padding: 12, backgroundColor: '#fff' }}
              />
            </React.Suspense>
          )}
        </div>
        <input
          type="text"
          placeholder="Enter a barcode value"
          onChange={event => setQrCode(event.target.value)}
          value={qrCode}
          css={{ maxWidth: 200 }}
          autoFocus
        />
        <Button onClick={() => setIsQrCodeVisible(state => !state)}>
          {isQrCodeVisible ? 'Hide' : 'Show'}
        </Button>
      </div>
    </div>
  );
}
