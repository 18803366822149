/**
 * Order delivery times for all locations.
 *
 * Array of arrays of three values:
 * - hour
 * - delivery time today (if hour < current hour)
 * - delivery time tomorrow (if hour >= current hour
 *
 * Each item corresponds to one day, zero-indexed to Sunday.
 *
 * To get the delivery time today:
 *
 *   const date = new Date();
 *   const dayIndex = date.getDay();
 *   const [hour, timeToday, timeTomorrow] = deliveryTimes[dayIndex];
 *   const deliveryTime = date.getHours() < hour ? timeToday : timeTomorrow;
 */
export const deliveryTimes = [
  // Sunday
  [10, 'kl. 16 í dag', 'kl. 12 á morgun'],
  // Monday
  [12, 'kl. 16 í dag', 'kl. 12 á morgun'],
  // Tuesday
  [12, 'kl. 16 í dag', 'kl. 12 á morgun'],
  // Wednesday
  [12, 'kl. 16 í dag', 'kl. 12 á morgun'],
  // Thursday
  [12, 'kl. 16 í dag', 'kl. 12 á morgun'],
  // Friday
  [12, 'kl. 16 í dag', 'kl. 16 á morgun'],
  // Saturday
  [10, 'kl. 16 í dag', 'kl. 12 á morgun'],
];
