import { AuthForm } from './authenticate';

export function ChangePassword(props) {
  const params = new URLSearchParams(props.location.search);
  const token = params.get('token');

  return (
    <AuthForm
      endpoint="/auth/change-password"
      title="Breyta lykilorði"
      action="Skrá nýtt lykilorð"
      callback={response => {
        if (!response.error) {
          props.history.push('/authenticate?passwordChanged=t');
        }
      }}
      form={[
        {
          type: 'hidden',
          name: 'token',
          value: token,
          styles: { display: 'none' },
        },
        {
          label: 'Nýtt lykilorð',
          type: 'password',
          placeholder: 'Lykilorð',
          autoFocus: true,
        },
      ]}
      links={[
        {
          url: '/authenticate',
          label: 'Innskráning',
        },
        {
          url: '/reset-password',
          label: 'Glatað lykilorð',
        },
      ]}
    />
  );
}
