import React from 'react';
import { Switch, Route } from 'react-router-dom';
import styled from '@emotion/styled';

import { NotFound } from './not-found';
import { colors, fonts } from '../constants';

import { Button } from '../forms';
import { AnimateLoad } from '../router';
import { Orders, Users, Truck } from '../icons';
import { ConsignmentOverview } from './consignment-overview';
import { ConsignmentTransit } from './consignment-transit';
import { ConsignmentCustomer } from './consignment-customer';

const LargeButton = styled(Button)({
  height: 60,
  fontFamily: fonts.display,
  fontSize: 18,
  fontWeight: 400,
  color: colors.blue,
  backgroundColor: colors.white,
  border: `3px solid ${colors.paleBlue}`,
  borderRadius: 5,
  display: 'flex',
  alignItems: 'center',
  span: {
    marginLeft: 12,
  },
  ':active': {
    color: colors.white,
    backgroundColor: colors.blue,
    border: `3px solid ${colors.blue}`,
  },
  // For the SVG icons we are currently using, in two cases it's the
  // second path element and in the other one it's the only path element.
  ':active path:only-child, :active path + path': {
    fill: colors.white,
  },
});

function Home({ history }) {
  return (
    <div
      css={{
        margin: '0 auto',
        marginTop: 24,
        display: 'flex',
        flexDirection: 'column',
        width: 220,
        '>*:not(:last-of-type)': {
          marginBottom: 20,
        },
      }}
    >
      <LargeButton onClick={() => history.push('/afhending')}>
        <Users color={colors.paleBlue} />
        <span>Afhenda pakka</span>
      </LargeButton>
      <LargeButton onClick={() => history.push('/mottaka')}>
        <Truck />
        <span>Móttaka pakka</span>
      </LargeButton>
      <LargeButton onClick={() => history.push('/yfirlit')}>
        <Orders />
        <span>Yfirlit</span>
      </LargeButton>
    </div>
  );
}

export function Consignment(props) {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={AnimateLoad(innerProps => (
          <Home {...props} {...innerProps} />
        ))}
      />
      <Route
        exact
        path="/afhending"
        render={AnimateLoad(innerProps => (
          <ConsignmentCustomer {...props} {...innerProps} />
        ))}
      />
      <Route
        exact
        path="/mottaka"
        render={AnimateLoad(innerProps => (
          <ConsignmentTransit {...props} {...innerProps} />
        ))}
      />
      <Route
        exact
        path="/yfirlit"
        render={AnimateLoad(innerProps => (
          <ConsignmentOverview {...props} {...innerProps} />
        ))}
      />
      <Route component={AnimateLoad(NotFound)} />
    </Switch>
  );
}
