import React from 'react';

import { colors } from '../constants';
import { Heading, Text } from '../typography';
import { Card } from '../forms';

export function ConsignmentOverview() {
  const [status, setStatus] = React.useState(null);

  function fetchStatus() {
    fetch('/app/consignment-status')
      .then(res => res.json())
      .then(response => {
        setStatus(response);
      });
  }

  React.useEffect(() => {
    fetchStatus();
  }, []);

  return (
    <>
      <Heading>Yfirlit</Heading>
      <div
        css={{
          '>div:not(:first-of-type)': { marginTop: 12 },
          '@media (min-width: 600px)': {
            display: 'flex',
            '>div:first-of-type': { marginRight: 12 },
            '>div:not(:first-of-type)': { marginTop: 6 },
          },
        }}
      >
        {status && (
          <div css={{ marginTop: 6, flex: 1 }}>
            <Heading css={{ fontSize: 16, color: colors.paleBlue }}>
              Pantanir
            </Heading>
            <Card css={{ marginTop: 6 }}>
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Text>Mótteknar pantanir:</Text>
                <Text css={{ fontSize: 18, fontWeight: 400 }}>
                  {status.consignment || 0}
                </Text>
              </div>
              <div
                css={{
                  marginTop: 6,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Text>Pantanir á leið:</Text>
                <Text css={{ fontSize: 18, fontWeight: 400 }}>
                  {status.transit || 0}
                </Text>
              </div>
              <div
                css={{
                  marginTop: 6,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Text>Pantanir í vöruhúsi:</Text>
                <Text css={{ fontSize: 18, fontWeight: 400 }}>
                  {status.initial || 0}
                </Text>
              </div>
            </Card>
          </div>
        )}
      </div>
    </>
  );
}
