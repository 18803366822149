import React from 'react';
import styled from '@emotion/styled';

import { Heading as SmallHeading, Text as SmallText } from '../typography';
import { colors } from '../constants';

function trim(string) {
  let lines = string.split('\n');
  let minIndentation = Infinity;
  lines.forEach(line => {
    const index = line.search(/\S/);
    if (index > -1 && index < minIndentation) {
      minIndentation = index;
    }
  });
  if (lines[0].search(/\S/)) {
    lines = lines.slice(1);
  }
  if (lines[lines.length - 1].search(/\S/)) {
    lines = lines.slice(0, -1);
  }
  return lines.map(line => line.slice(minIndentation)).map(line => line + '\n');
}

const Heading = styled(SmallHeading)(props => ({
  color: '#1e1e1e',
  fontWeight: 400,
  fontSize: props.as === 'h2' ? 24 : props.as === 'h3' ? 18 : 32,
}));

const Text = styled(SmallText)({
  lineHeight: '1.6em',
  fontSize: 18,
  color: '#4e4e4e',
  code: {
    fontSize: 15,
    color: '#6d6d6d',
  },
});

const Code = styled('code')(
  {
    fontFamily: 'Roboto mono, monospace',
    lineHeight: '1.4em',
  },
  props => props.multiline && { whiteSpace: 'pre' },
);

const List = styled('ul')({
  lineHeight: '1.5em',
  fontSize: 18,
  color: '#4e4e4e',
  code: {
    fontSize: 15,
    color: '#6d6d6d',
  },
});

const Anchor = styled('a')({
  color: colors.blue,
  ':active': {
    color: colors.teal,
  },
});

function CodeBlock({ children }) {
  return (
    <Code
      css={{
        display: 'block',
        padding: '16px 12px',
        borderLeft: `4px solid ${colors.teal}`,
        color: '#5f5f5f',
        backgroundColor: colors.background,
        whiteSpace: 'pre',
        overflow: 'scroll',
      }}
    >
      {trim(children)}
    </Code>
  );
}

export function Docs({ locations }) {
  return (
    <div
      css={{
        maxWidth: 960,
        margin: '24px auto 32px',
        backgroundColor: '#fff',
        borderRadius: 4,
        padding: '36px 32px',
        '>*:not(:last-child)': {
          marginBottom: '16px !important',
        },
        '>h2:not(:last-child)': {
          marginBottom: '8px !important',
        },
        '>h2': {
          marginTop: '32px !important',
        },
        ul: {
          marginTop: -10,
          paddingLeft: 19,
        },
        'ul ul': {
          marginTop: 0,
        },
      }}
    >
      <link
        href="https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap"
        rel="stylesheet"
      />
      <Heading css={{ color: colors.blue }}>Dropp Documentation</Heading>
      <Heading as="h2">Introduction</Heading>
      <Text>
        Integrating with Dropp involves modifying your online store to allow the
        customer to choose a Dropp location (see{' '}
        <Anchor href="#cart">Cart integration</Anchor> below), and making a{' '}
        <Code>POST</Code> request to our API in to create the order in our
        system (see <Anchor href="#authorization">Authorization</Anchor> and API
        endpoints below).
      </Text>
      <Heading as="h2" id="cart">
        Cart integration
      </Heading>
      <Text>
        Integrating Dropp in your online store purchase flow requires adding a{' '}
        <Code>{'<script>'}</Code> element to the page, and adding a button to
        trigger a function in the script. When called, this function will show a
        modal (pictured below) where customers can select one of the Dropp
        locations.
      </Text>
      <img
        src="/Dropp modal.jpg"
        css={{ maxWidth: '100%', padding: '12px 24px' }}
      ></img>
      <Text>
        To include the script, add the following HTML to your website:
      </Text>
      <CodeBlock>
        {`<script type="application/javascript" src="//app.dropp.is/dropp-locations.min.js" async></script>`}
      </CodeBlock>
      <Text>
        The above script will make the <Code>chooseDroppLocation</Code>{' '}
        JavaScript function available globally, once it has loaded (please make
        sure that the function is available before attempting to call it). The
        function returns a promise, which resolves to the location that was
        chosen, if any. You should submit the location <Code>id</Code>, and any
        other attributes required for the store, to the server along with the
        purchase request, and later include the location <Code>id</Code> as{' '}
        <Code>locationId</Code> in the <Code>POST /orders</Code> call. Note that
        the modal requires modern JavaScript APIs, such as <Code>Promise</Code>,{' '}
        <Code>Map</Code>, <Code>Set</Code> to be available. If your users have
        old browsers, these can be polyfilled (you can either update your
        website assets or use a service like{' '}
        <a href="https://polyfill.io" target="_blank" rel="noopener noreferrer">
          polyfill.io
        </a>
        ).
      </Text>
      <CodeBlock>
        {`
        chooseDroppLocation()
          .then(location => {
            // location is either null (if the user dismissed the modal),
            // or a location object with an UUID as its id.
          })
          .catch(error => {
            // The modal may throw errors, for example if it cannot display locations.
          });
        `}
      </CodeBlock>
      <Heading as="h2" id="authorization">
        Authorization
      </Heading>
      <Text>
        In order to make requests to the API, a secret API key must be provided.
        Keys can be viewed and reset in our web app, and access is provided by
        invitation. The following API endpoints are intended for
        server-to-server communication only, and authorization is requested by
        adding an <Code>Authorization: Basic YOUR-TOKEN</Code> header to
        requests, with the client id and secret key as the user and password
        respectively, base 64 encoded as the token.
      </Text>
      <Heading as="h3">Example request</Heading>
      <CodeBlock>
        {`
        curl -H 'Authorization: Basic aWQ6cGFzcw==' https://app.dropp.is/api/v1
        `}
      </CodeBlock>
      <Heading as="h2" id="post-orders">
        <Code>POST /orders</Code>
      </Heading>
      <Text>
        Creates an order and associated product(s) in the Dropp system,
        returning the created order and its product(s). All product and customer
        attributes are required. The <Code>locationId</Code> is the location
        selected by your customer. <Code>barcode</Code> is a unique order Id
        from your system, printed on the package. Returns an order with the
        provided attributes, in addition to an <Code>id</Code> for the order in
        our systems, and the order's status, which can be <Code>"initial"</Code>{' '}
        (order created & ready to be picked up), <Code>"transit"</Code> (package
        in transit to delivery location), <Code>"consignment"</Code> (package at
        delivery location) or <Code>"delivered"</Code> (customer has picked up
        the package).
      </Text>
      <List>
        <li>Authorization is required.</li>
        <li>Required parameters:</li>
        <List>
          <li>
            <Code>locationId</Code> – The location selected by your customer.
          </li>
          <li>
            <Code>barcode</Code> – A unique order Id from your systems printed
            on the package, used by us to identify the package. This is not an
            individual product's barcode.
          </li>
          <li>
            <Code>products</Code> (array of objects) – Product names and unique
            product barcodes.
            <List>
              <li>
                <Code>name</Code>
              </li>
              <li>
                <Code>barcode</Code>
              </li>
            </List>
          </li>
          <li>
            <Code>customer</Code> (object)
            <List>
              <li>
                <Code>name</Code>
              </li>
              <li>
                <Code>emailAddress</Code>
              </li>
              <li>
                <Code>socialSecurityNumber</Code>
              </li>
              <li>
                <Code>address</Code>
              </li>
              <li>
                <Code>phoneNumber</Code>
              </li>
            </List>
          </li>
        </List>
      </List>
      <Heading as="h3">Example request</Heading>
      <CodeBlock>
        {`
        curl -X POST \\
             -H "Content-Type: application/json" \\
             -H "Authorization: Basic <auth token>" \\
             -d '{"locationId": "${locations[0].id}", "barcode": "ORDER-AB123", "products": [{"name": "Atgeir", "barcode": "A123"}], "customer": {"name": "Egill Skallagrímsson", "emailAddress": "egill@skallagrimsson.is", "socialSecurityNumber": "010101-0101", "address": "Borg á Mýrum", "phoneNumber": "777 7777"}}' \\
            https://app.dropp.is/api/v1/orders
        `}
      </CodeBlock>
      <Heading as="h3">Example response</Heading>
      <CodeBlock>
        {`
        {
          "id": "de3128aa-acf6-42c8-a5f3-3501eb23133e",
          "barcode": "ORDER-AB123",
          "locationId": "${locations[0].id}",
          "products": [{"name": "Atgeir", "barcode": "A123"}],
          "customer": {"name": "Egill Skallagrímsson", "emailAddress": "egill@skallagrimsson.is", "socialSecurityNumber": "010101-0101", "address": "Borg á Mýrum", "phoneNumber": "777 7777"},
          "status": "initial",
          "createdAt": "2019-06-01T00:00:00.000Z",
          "updatedAt": "2019-06-01T00:00:00.000Z"
        }
      `}
      </CodeBlock>
      <Heading as="h2" id="get-orders">
        <Code>GET /orders/:order-id</Code>
      </Heading>
      <Text>Returns an order and its product(s).</Text>
      <List>
        <li>Authorization is required.</li>
        <li>No parameters required.</li>
      </List>
      <Heading as="h3">Example request</Heading>
      <CodeBlock>
        {`
        curl -H "Authorization: Basic <auth token>" https://app.dropp.is/api/v1/orders/de3128aa-acf6-42c8-a5f3-3501eb23133e
        `}
      </CodeBlock>
      <Heading as="h3">Example response</Heading>
      <CodeBlock>
        {`
        {
          "id": "de3128aa-acf6-42c8-a5f3-3501eb23133e",
          "barcode": "ORDER-AB123",
          "locationId": "${locations[0].id}",
          "products": [{"name": "Atgeir", "barcode": "A123"}],
          "customer": {"name": "Egill Skallagrímsson", "emailAddress": "egill@skallagrimsson.is", "socialSecurityNumber": "010101-0101", "address": "Borg á Mýrum", "phoneNumber": "777 7777"},
          "status": "initial",
          "createdAt": "2019-06-01T00:00:00.000Z",
          "updatedAt": "2019-06-01T00:00:00.000Z"
        }
      `}
      </CodeBlock>
      <Heading as="h2" id="patch-orders">
        <Code>PATCH /orders/:order-id</Code>
      </Heading>
      <Text>
        Updates an order and returns an empty response. Please note that the
        order may only be updated while its <Code>status</Code> is{' '}
        <Code>"initial"</Code>.
      </Text>
      <List>
        <li>Authorization is required.</li>
        <li>Allowed parameters (at least one required):</li>
        <List>
          <li>
            <Code>barcode</Code>
          </li>
          <li>
            <Code>locationId</Code>
          </li>
          <li>
            <Code>status</Code> – must be <Code>"initial"</Code>
          </li>
          <li>
            <Code>customer</Code>
          </li>
          <li>
            <Code>products</Code>
          </li>
        </List>
      </List>
      <CodeBlock>
        {`
        curl -X PATCH \\
             -H "Content-Type: application/json" \\
             -H "Authorization: Basic <auth token>" \\
             -d '{"locationId": "${locations[1].id}"}' \\
            https://app.dropp.is/api/v1/orders/de3128aa-acf6-42c8-a5f3-3501eb23133e
        `}
      </CodeBlock>
      <Heading as="h3">Example response</Heading>
      <CodeBlock>{'{}'}</CodeBlock>
      <Heading as="h2" id="delete-orders">
        <Code>DELETE /orders/:order-id</Code>
      </Heading>
      <Text>
        Deletes an order and returns an empty response. Can only be performed
        before collection, after this the order must be recalled.
      </Text>
      <CodeBlock>
        {`
        curl -X DELETE \\
             -H "Authorization: Basic <auth token>" \\
             https://app.dropp.is/api/v1/orders/de3128aa-acf6-42c8-a5f3-3501eb23133e
        `}
      </CodeBlock>
      <Heading as="h3">Example response</Heading>
      <CodeBlock>{'{}'}</CodeBlock>
    </div>
  );
}
