import React from 'react';

import { colors, fonts, zIndices } from './constants';
import { Logo, Menu } from './icons';
import { Link } from './router';
import { useOnClickOutside } from './modal';

export function Navigation({ user, styles = {} }) {
  const menuRef = React.useRef();
  const [isMenuOpen, openMenu] = React.useState(false);



  useOnClickOutside(menuRef, () => openMenu(false));

    function closeMenu() {
        openMenu(false);
    }


  function logOut(event) {
    event.preventDefault();

    return fetch('/auth/log-out', { method: 'DELETE' }).then(() => {
      window.location = '/authenticate';
    });
  }

  return (
    <nav
      css={{
        height: 50,
        backgroundColor: colors.blue,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 12px',
        zIndex: zIndices.navigation,
        ...styles,
      }}
    >
      <Link to="/" css={{ lineHeight: 0 }}>
        <Logo width={32} height={32} />
      </Link>
      <Link
        to="/"
        css={{
          fontFamily: fonts.text,
          fontSize: 20,
          letterSpacing: 0.5,
          fontWeight: 'bold',
          color: colors.teal,
          textDecoration: 'none',
        }}
      >
        dropp
      </Link>
      <div css={{ position: 'relative', marginTop: 4, marginLeft: 8 }}>
        <Menu
          styles={{
            visibility: user ? 'visible' : 'hidden',
            ':hover': {
              cursor: 'pointer',
            },
          }}
          onClick={() => openMenu(!isMenuOpen)}
        />
        {isMenuOpen && (
          <ul
            ref={menuRef}
            css={{
              position: 'absolute',
              top: 48,
              right: 0,
              backgroundColor: colors.white,
              margin: 0,
              padding: '16px 16px 18px',
              borderRadius: 3,
              boxShadow: '0 1px 3px 2px rgba(0, 0, 0, .15)',
              zIndex: zIndices.dropdown,
              listStyleType: 'none',
              'li:not(:last-of-type)': {
                marginBottom: 8,
              },
            }}
          >
            <li css={{ color: colors.paleBlue }}>{user.email}</li>
              { user.role === 'consignment' &&
              <li><Link to="/afhending" onClick={closeMenu}>
                  Afhending
              </Link>
              </li>
              }
              { user.role === 'consignment' &&
              <li><Link to="/mottaka" onClick={closeMenu}>
                  Móttaka
              </Link>
              </li>
              }
              { user.role === 'consignment' &&
              <li><Link to="/yfirlit" onClick={closeMenu}>
                  Yfirlit
              </Link>
              </li>
              }
            <li>
              <Link onClick={logOut} to="/">
                Útskrá
              </Link>
            </li>
          </ul>
        )}
      </div>
    </nav>
  );
}
